import {
	// SHOW_ALERT,
	// SHOW_CONFIRM,
	// HIDE_CONFIRM,
	// SHOW_NETWORK_ACTIVITY,
	// HIDE_NETWORK_ACTIVITY,
	CoordinatorKeys,
} from './../types'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

export const startWatchingCoordinator = (dispatch, getState) => {
	const {
		FETCH_COORDINATOR,
		SAVE_COORDINATOR_WATCHER,
		FETCH_ORGANIZATION,
		SAVE_ORGANIZATION_WATCHER,
	} = CoordinatorKeys

	const { uid } = firebase.auth().currentUser
	const coordinatorWatcherRef = firebase.database().ref(`Coordinators/${uid}`)
	coordinatorWatcherRef.on(
		'value',
		(snapshot) => {
			dispatch({
				type: FETCH_COORDINATOR,
				payload: snapshot.val(),
			})

			if (
				snapshot.val() &&
				snapshot.val().organizationUID &&
				!getState().Coordinator.organizationWatcherRef
			) {
				const organizationWatcherRef = firebase
					.database()
					.ref(`CoordinatorOrganizations/${snapshot.val().organizationUID}`)
				organizationWatcherRef.on('value', (snapshot) => {
					dispatch({
						type: FETCH_ORGANIZATION,
						payload: snapshot.val(),
					})
				})
				dispatch({
					type: SAVE_ORGANIZATION_WATCHER,
					payload: organizationWatcherRef,
				})
			}
		},
		(error) => console.log(error)
	)

	dispatch({
		type: SAVE_COORDINATOR_WATCHER,
		payload: coordinatorWatcherRef,
	})
}
export const stopWatchingCoordinator = (dispatch, getState) => {
	const { coordinatorWatcherRef, organizationWatcherRef } = getState().Coordinator
	if (coordinatorWatcherRef) {
		coordinatorWatcherRef.off()
		dispatch({ type: CoordinatorKeys.REMOVE_COORDINATOR_WATCHER })
	}
	if (organizationWatcherRef) {
		organizationWatcherRef.off()
		dispatch({ type: CoordinatorKeys.REMOVE_ORGANIZATION_WATCHER })
	}
}

