import { TrackingKeys } from './../../actions/types'

const INITIAL_STATE = {
	circuit: undefined,
	circuitLoaded: false,
	circuitWatcherRef: undefined,

	circuitWaitTimes: undefined,
	circuitWaitTimesWatchers: undefined,
	circuitWaitTimesIntervals: undefined,
}

export default (state = INITIAL_STATE, action) => {
	const {
		FETCH_CIRCUIT,
		SAVE_CIRCUIT_WATCHER,
		REMOVE_CIRCUIT_WATCHER,

		FETCH_WAIT_TIME,
		SAVE_WAIT_TIME_WATCHER,
		REMOVE_WAIT_TIME_WATCHER,
	} = TrackingKeys

	switch (action.type) {
		case FETCH_CIRCUIT:
			return { ...state, circuit: action.payload, circuitLoaded: true }
		case SAVE_CIRCUIT_WATCHER:
			return { ...state, circuitWatcherRef: action.payload }
		case REMOVE_CIRCUIT_WATCHER:
			return {
				...state,
				circuit: INITIAL_STATE.circuit,
				circuitLoaded: INITIAL_STATE.circuitLoaded,
				circuitWatcherRef: INITIAL_STATE.circuitWatcherRef,
			}

		case FETCH_WAIT_TIME:
			return { ...state, circuitWaitTimes: action.payload }
		case SAVE_WAIT_TIME_WATCHER: {
			const { waitTimeWatcherRef, waitTimeInterval } = action.payload

			return {
				...state,
				circuitWaitTimesWatchers: waitTimeWatcherRef,
				circuitWaitTimesIntervals: waitTimeInterval,
			}
		}
		case REMOVE_WAIT_TIME_WATCHER:
			return {
				...state,
				circuitWaitTimes: INITIAL_STATE.circuitWaitTimes,
				circuitWaitTimesWatchers: INITIAL_STATE.circuitWaitTimesWatchers,
				circuitWaitTimesIntervals: INITIAL_STATE.circuitWaitTimesIntervals,
			}

		default:
			return state
	}
}
