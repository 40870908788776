//GLOBAL
export const SHOW_ALERT = 'show_alert'
export const HIDE_ALERT = 'hide_alert'
export const SHOW_CONFIRM = 'show_confirm'
export const HIDE_CONFIRM = 'hide_confirm'
export const SHOW_NETWORK_ACTIVITY = 'show_network_activity'
export const HIDE_NETWORK_ACTIVITY = 'hide_network_activity'
export const SET_USER_ACTIVE = 'set_user_active'
export const SET_USER_INACTIVE = 'set_user_inactive'
export const SET_PAGE_TITLE = 'set_page_title'

//
// Global Stores
//
//Auth
export const AuthKeys = {
	//Inputs
	SIGN_IN_EMAIL_CHANGED: 'auth__sign_in_email_changed',
	SIGN_IN_PASSWORD_CHANGED: 'auth__sign_in_password_changed',

	REGISTER_USER_TYPE_CHANGED: 'auth__register_user_type_changed',
	REGISTER_EMAIL_CHANGED: 'auth__register_email_changed',
	REGISTER_PASSWORD_CHANGED: 'auth__register_password_changed',
	REGISTER_PASSWORD_CONFIRM_CHANGED: 'auth__register_password_confirm_changed',

	FORGOT_EMAIL_CHANGED: 'auth__forgot_email_changed',

	RESET_PASSWORD_CHANGED: 'auth__reset_password_changed',

	//Requests
	SIGN_IN_USER_SUCCESS: 'auth__sign_in_user_success',
	SIGN_IN_USER_FAIL: 'auth__sign_in_user_fail',

	SIGN_OUT_USER_SUCCESS: 'auth__sign_out_user_success',

	REGISTER_AGENT_SUCCESS: 'auth__register_agent_success',
	REGISTER_AGENT_FAIL: 'auth__register_agent_fail',

	REGISTER_CLIENT_SUCCESS: 'auth__register_client_success',
	REGISTER_CLIENT_FAIL: 'auth__register_client_fail',

	REGISTER_ADMIN_SUCCESS: 'auth__register_admin_success',
	REGISTER_ADMIN_FAIL: 'auth__register_admin_fail',

	REGISTER_USER_SUCCESS: 'auth__register_user_success',
	REGISTER_USER_FAIL: 'auth__register_user_fail',

	RESET_PASSWORD_SUCCESS: 'auth__reset_password_success',
	RESET_PASSWORD_FAIL: 'auth__reset_password_fail',

	SEND_FORGOT_PASSWORD_EMAIL_SUCCESS: 'auth__send_forgot_password_email_success',
	SEND_FORGOT_PASSWORD_EMAIL_FAIL: 'auth__send_forgot_password_email_fail',

	FETCH_USER_PERMISSIONS: 'auth__load_user_permissions',
	SAVE_USER_PERMISSIONS_WATCHER: 'auth__save_user_permissions_watcher',
	REMOVE_USER_PERMISSIONS_WATCHER: 'auth__remove_user_permissions_watcher',
}

//Loading
export const LoadingKeys = {
	USER_CHECK_STARTED: 'loading__user_check_started',
	USER_CHECK_COMPLETE: 'loading__user_check_complete',
	FETCH_APP_VERSIONS: 'loading__fetch_app_versions',
}

//
//Front End
//
//Home
export const HomeKeys = {}

export const TrackingKeys = {
	FETCH_ROUTE: 'tracking__fetch_route',
	SAVE_ROUTE_WATCHER: 'tracking__save_route_watcher',
	REMOVE_ROUTE_WATCHER: 'tracking__remove_route_watcher',

	FETCH_CIRCUIT: 'tracking__fetch_circuit',
	SAVE_CIRCUIT_WATCHER: 'tracking__save_circuit_watcher',
	REMOVE_CIRCUIT_WATCHER: 'tracking__remove_circuit_watcher',

	FETCH_WAIT_TIME: 'tracking__fetch_wait_time',
	SAVE_WAIT_TIME_WATCHER: 'tracking__save_wait_time_watcher',
	REMOVE_WAIT_TIME_WATCHER: 'tracking__remove_wait_time_watcher',

	//
	//Program
	//
	FETCH_PROGRAM: 'tracking__fetch_program',
	SAVE_PROGRAM_WATCHER: 'tracking__save_program_watcher',
	REMOVE_PROGRAM_WATCHER: 'tracking__remove_program_watcher',

	FETCH_PROGRAM_ROUTES: 'tracking__fetch_program_routes',
	FETCH_PROGRAM_CIRCUITS: 'tracking__fetch_program_circuits',
	SAVE_PROGRAM_REFRESHER: 'tracking__save_program_refresher',
	SET_PROGRAM_REFRESH_RATE: 'tracking__set_program_refresh_rate',
}

//
//Admin
//
export const AdminKeys = {
	SHOW_ADD_COORDINATOR_POPUP: 'admin__show_add_coordinator_create_popup',
	HIDE_ADD_COORDINATOR_POPUP: 'admin__hide_add_coordinator_create_popup',

	ORGANIZATION_NAME_CHANGED: 'admin__organization_name_changed',

	COORDINATOR_FIRST_NAME_CHANGED: 'admin__coordinator_first_name_changed',
	COORDINATOR_LAST_NAME_CHANGED: 'admin__coordinator_last_name_changed',
	COORDINATOR_EMAIL_CHANGED: 'admin__coordinator_email_changed',
	COORDINATOR_PASSWORD_CHANGED: 'admin__coordinator_password_changed',
	COORDINATOR_ACCOUNT_ACTIVE_CHANGED: 'admin__coordiantor_account_active_changed',

	FETCH_ORGANIZATIONS: 'admin__fetch_organizations',
	SAVE_ORGANIZATIONS_WATCHER: 'admin__save_organizations_watcher',
	REMOVE_ORGANIZATIONS_WATCHER: 'admin__remove_organizations_watcher',

	FETCH_ORGANIZATION: 'admin__fetch_organization',
	SAVE_ORGANIZATION_WATCHER: 'admin__save_organization_watcher',
	REMOVE_ORGANIZATION_WATCHER: 'admin__remove_organization_watcher',

	FETCH_COORDINATORS: 'admin__fetch_coordinators',
	SAVE_COORDINATORS_WATCHER: 'admin__save_coordinators_watcher',
	REMOVE_COORDINATORS_WATCHER: 'admin__remove_coordinators_watcher',

	FETCH_COORDINATOR: 'admin__fetch_coordinator',
	SAVE_COORDINATOR_WATCHER: 'admin__save_coordinator_watcher',
	REMOVE_COORDINATOR_WATCHER: 'admin__remove_coordinator_watcher',

	CREATE_ORGANIZATION_SUCCESS: 'admin_create_organization_success',
	CREATE_ORGANIZATION_HIDE: 'admin_create_organization_hide',

	CREATE_COORDINATOR_SUCCESS: 'admin__create_coordinator_success',
	CREATE_COORDINATOR_FAIL: 'admin__create_coordinator_fail',

	CIRCUIT_API_CIRCUIT_UID_CHANGED: 'admin__circuit_api_circuit_uid_changed',
	CIRCUIT_API_LOCATION_UID_CHANGED: 'admin__circuit_api_location_uid_changed',
	CIRCUIT_API_WAIT_CHANGED: 'admin__circuit_api_wait_changed',

	CIRCUIT_API_TEST_START: 'admin__circuit_api_test_start',
	CIRCUIT_API_TEST_STOP: 'admin__circuit_api_test_stop',

	FETCH_CIRCUIT_API_RESPONSE: 'admin__fetch_circuit_api_response',

	RESET: 'admin__reset',
}

//
//Coordinator
//
export const CoordinatorKeys = {
	//
	//Coordinator
	//
	FETCH_COORDINATOR: 'coordinator__fetch_coordinator',
	SAVE_COORDINATOR_WATCHER: 'coordinator__save_coordinator_watcher',
	REMOVE_COORDINATOR_WATCHER: 'coordinator__remove_coordinator_watcher',

	FETCH_ORGANIZATION: 'coordinator__fetch_organization',
	SAVE_ORGANIZATION_WATCHER: 'coordinator__save_organization_watcher',
	REMOVE_ORGANIZATION_WATCHER: 'coordinator__remove_organization_watcher',

	//
	// Route
	//
	FETCH_ROUTES: 'coordinator__fetch_routes',
	SAVE_ROUTES_WATCHER: 'coordinator__save_routes_watcher',
	REMOVE_ROUTES_WATCHER: 'coordinator__remove_routes_watcher',

	FETCH_ROUTES_LIVE: 'coordinator__fetch_routes_live',
	SAVE_ROUTES_LIVE_WATCHER: 'coordinator__save_routes_live_watcher',
	REMOVE_ROUTES_LIVE_WATCHER: 'coordinator__remove_routes_live_watcher',

	FETCH_ROUTES_HISTORY: 'coordinator__fetch_routes_history',
	SAVE_ROUTES_HISTORY_WATCHER: 'coordinator__save_routes_history_watcher',
	REMOVE_ROUTES_HISTORY_WATCHER: 'coordinator__remove_routes_history_watcher',

	FETCH_ROUTE: 'coordinator__fetch_route',
	SAVE_ROUTE_WATCHER: 'coordinator__save_route_watcher',
	REMOVE_ROUTE_WATCHER: 'coordinator__remove_route_watcher',

	FETCH_ROUTE_LOGS: 'coordinator__fetch_route_logs',
	SAVE_ROUTE_LOGS_WATCHER: 'coordinator__save_route_logs_watcher',
	REMOVE_ROUTE_LOGS_WATCHER: 'coordinator__remove_route_logs_watcher',

	//
	//Circuit
	//
	FETCH_CIRCUITS_LIVE: 'coordinator__fetch_circuits_live',
	SAVE_CIRCUITS_LIVE_WATCHER: 'coordinator__save_circuits_live_watcher',
	REMOVE_CIRCUITS_LIVE_WATCHER: 'coordinator__remove_circuits_live_watcher',

	FETCH_CIRCUITS_HISTORY: 'coordinator__fetch_circuits_history',
	SAVE_CIRCUITS_HISTORY_WATCHER: 'coordinator__save_circuits_history_watcher',
	REMOVE_CIRCUITS_HISTORY_WATCHER: 'coordinator__remove_circuits_history_watcher',

	FETCH_WAIT_TIME: 'coordinator__fetch_wait_time',
	SAVE_WAIT_TIME_WATCHER: 'coordinator__save_wait_time_watcher',
	REMOVE_WAIT_TIME_WATCHER: 'coordinator__remove_wait_time_watcher',

	FETCH_CIRCUIT: 'coordinator__fetch_circuit',
	SAVE_CIRCUIT_WATCHER: 'coordinator__save_circuit_watcher',
	REMOVE_CIRCUIT_WATCHER: 'coordinator__remove_circuit_watcher',

	FETCH_CIRCUIT_LOGS: 'coordinator__fetch_circuit_logs',
	SAVE_CIRCUIT_LOGS_WATCHER: 'coordinator__save_circuit_logs_watcher',
	REMOVE_CIRCUIT_LOGS_WATCHER: 'coordinator__remove_circuit_logs_watcher',

	FETCH_PAX_COUNTS: 'coordinator__fetch_pax_counts',
	SAVE_PAX_COUNTS_WATCHER: 'coordinator__save_pax_counts_watcher',
	REMOVE_PAX_COUNTS_WATCHER: 'coordinator__remove_pax_counts_watcher',

	FETCH_PAX_DRIVERS: 'coordinator__fetch_pax_drivers',
	CLEAR_PAX_DRIVERS: 'coordinator__clear_pax_drivers',

	//
	//Program
	//
	FETCH_PROGRAMS_LIVE: 'coordinator__fetch_programs_live',
	SAVE_PROGRAMS_LIVE_WATCHER: 'coordinator__save_programs_live_watcher',
	REMOVE_PROGRAMS_LIVE_WATCHER: 'coordinator__remove_programs_live_watcher',

	FETCH_PROGRAMS_HISTORY: 'coordinator__fetch_programs_history',
	SAVE_PROGRAMS_HISTORY_WATCHER: 'coordinator__save_programs_history_watcher',
	REMOVE_PROGRAMS_HISTORY_WATCHER: 'coordinator__remove_programs_history_watcher',

	FETCH_PROGRAM: 'coordinator__fetch_program',
	SAVE_PROGRAM_WATCHER: 'coordinator__save_program_watcher',
	REMOVE_PROGRAM_WATCHER: 'coordinator__remove_program_watcher',

	FETCH_PROGRAM_ROUTES: 'coordinator__fetch_program_routes',
	FETCH_PROGRAM_CIRCUITS: 'coordinator__fetch_program_circuits',
	SAVE_PROGRAM_REFRESHER: 'coordinator__save_program_refresher',
	SET_PROGRAM_REFRESH_RATE: 'coordinator__set_program_refresh_rate',

	//
	// Fleet
	//
	FETCH_FLEET: 'coordinator__fetch_fleet',
	SAVE_FLEET_WATCHER: 'coordinator__save_fleet_watcher',
	REMOVE_FLEET_WATCHER: 'coordinator__remove_fleet_watcher',

	SAVE_FLEET_REFRESHER: 'coordinator__save_fleet_refresher',
	FETCH_FLEET_DRIVERS: 'coordinator__fetch_fleet_drivers',
	FETCH_FLEET_DRIVER_LOCATIONS: 'coordinator__fetch_fleet_driver_locations',
}
