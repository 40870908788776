import validate from 'validate.js'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

import { SHOW_ALERT, SHOW_NETWORK_ACTIVITY, HIDE_NETWORK_ACTIVITY, AuthKeys } from './types'

import { startWatchingUserPermissions } from './LoadingActions'
import { startWatchingCoordinator } from './Coordinator/CoordinatorActions'

import { validateInputData } from './../helpers'

import {
	determineAuthErrorMessage,
	//determineForgotPasswordErrorMessage,
} from './../helpers'

export const signInUser = (data) => {
	const { email, password, history } = data

	//Validate Data
	const validatorConstraints = {
		email: {
			presence: {
				allowEmpty: false,
			},
			email: true,
		},
		password: {
			presence: {
				allowEmpty: false,
			},
		},
	}
	const validationResponse = validate(data, validatorConstraints)
	if (validationResponse) {
		return (dispatch) => {
			dispatch({
				type: SHOW_ALERT,
				payload: {
					alertTitle: 'Error',
					alertMessage: Object.values(validationResponse)[0][0],
				},
			})
		}
	}

	return (dispatch, getState) => {
		dispatch({
			type: SHOW_NETWORK_ACTIVITY,
			payload: 'Signing In...',
		})

		firebase
			.auth()
			.signInWithEmailAndPassword(email, password)
			.then((user) => {
				const { uid } = firebase.auth().currentUser

				//Check User Permissions
				return firebase
					.database()
					.ref(`UserPermissions/${uid}`)
					.once('value')
					.then((snapshot) => {
						dispatch({
							type: AuthKeys.FETCH_USER_PERMISSIONS,
							payload: snapshot.val(),
						})

						dispatch({ type: HIDE_NETWORK_ACTIVITY })
						if (snapshot.val()) {
							if (snapshot.val().admin) {
								history.push('/admin/organizations')
								startWatchingUserPermissions(dispatch, getState)
							} else if (snapshot.val().coordinator) {
								history.push('/portal/welcome')
								startWatchingUserPermissions(dispatch, getState)
								startWatchingCoordinator(dispatch, getState)
							} else {
								dispatch({
									type: SHOW_ALERT,
									payload: {
										alertTitle: 'Error Signing In',
										alertMessage:
											'Your user permissions do not allow you to sign into this website.',
									},
								})
							}
						} else {
							console.log('User does not have any user permissions')
							dispatch({
								type: SHOW_ALERT,
								payload: {
									alertTitle: 'Error Signing In',
									alertMessage: 'An error occurred signing in.',
								},
							})
						}
					})
			})

			.catch((error) => {
				console.error(error)
				dispatch({ type: HIDE_NETWORK_ACTIVITY })
				dispatch({
					type: SHOW_ALERT,
					payload: determineAuthErrorMessage(error),
				})
			})
	}
}

export const signOutUser = (data) => {
	const { history } = data
	return (dispatch, getState) => {
		dispatch({
			type: SHOW_NETWORK_ACTIVITY,
			payload: 'Signing Out...',
		})

		firebase
			.auth()
			.signOut()
			.then(() => {
				dispatch({ type: HIDE_NETWORK_ACTIVITY })

				history.push('/')
			})
			.catch((error) => {
				console.error(error)
				dispatch({ type: HIDE_NETWORK_ACTIVITY })
				dispatch({
					type: SHOW_ALERT,
					payload: determineAuthErrorMessage(error),
				})
			})
	}
}

export const sendForgotPasswordEmail = (data) => {
	const { email } = data

	//Validate Data
	const validatorConstraints = {
		email: {
			presence: {
				allowEmpty: false,
			},
			email: true,
		},
	}
	const validationResponse = validate(data, validatorConstraints)
	if (validationResponse) {
		return (dispatch) =>
			new Promise((res, rej) => {
				dispatch({
					type: SHOW_ALERT,
					payload: {
						alertTitle: 'Error',
						alertMessage: Object.values(validationResponse)[0][0],
					},
				})
				return rej()
			})
	}

	return (dispatch) =>
		new Promise((res, rej) => {
			dispatch({
				type: SHOW_NETWORK_ACTIVITY,
				payload: 'Sending Forgot Password Email...',
			})

			firebase
				.auth()
				.sendPasswordResetEmail(email)
				.then(() => {
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Success',
							alertMessage:
								'An email has been sent to the provided address. Follow the instructions in the email to reset your password.',
						},
					})
					return res()
				})
				.catch((error) => {
					console.error(error)
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: determineAuthErrorMessage(error),
					})
					return rej()
				})
		})
}

export const resetPassword = (actionCode, newPasswordData) => {
	const { RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAIL } = AuthKeys

	//Validate
	if (!newPasswordData.valid) {
		return (dispatch) => {
			dispatch({
				type: SHOW_ALERT,
				payload: validateInputData(newPasswordData),
			})
		}
	}

	return (dispatch) => {
		dispatch({
			type: SHOW_NETWORK_ACTIVITY,
			payload: 'Resetting Password...',
		})

		firebase
			.auth()
			.verifyPasswordResetCode(actionCode)
			.then((email) => {
				firebase
					.auth()
					.confirmPasswordReset(actionCode, newPasswordData.value)
					.then((resp) => {
						dispatch({ type: HIDE_NETWORK_ACTIVITY })
						dispatch({ type: RESET_PASSWORD_SUCCESS })
						dispatch({
							type: SHOW_ALERT,
							payload: {
								alertTitle: 'Success',
								alertMessage: 'Your password has been reset.',
							},
						})
					})
					.catch((error) => {
						console.log('Firebase Error Reseting Password Catch', error)
						dispatch({ type: HIDE_NETWORK_ACTIVITY })
						dispatch({ type: RESET_PASSWORD_FAIL })
						dispatch({
							type: SHOW_ALERT,
							payload: determineAuthErrorMessage(error),
						})
					})
			})
			.catch((error) => {
				console.log('Firebase Error Reseting Password Catch', error)
				dispatch({ type: HIDE_NETWORK_ACTIVITY })
				dispatch({ type: RESET_PASSWORD_FAIL })
				dispatch({
					type: SHOW_ALERT,
					payload: determineAuthErrorMessage(error),
				})
			})
	}
}
export const recoverEmail = (actionCode) => {
	return (dispatch) => {
		dispatch({
			type: SHOW_NETWORK_ACTIVITY,
			payload: 'Recovering Email...',
		})

		firebase
			.auth()
			.checkActionCode(actionCode)
			.then((info) => {
				const { email } = info.data

				firebase
					.auth()
					.applyActionCode(actionCode)
					.then(() => {
						firebase
							.auth()
							.sendPasswordResetEmail(email)
							.then(() => {
								dispatch({ type: HIDE_NETWORK_ACTIVITY })
								dispatch({
									type: SHOW_ALERT,
									payload: {
										alertTitle: 'Success',
										alertMessage:
											'Your email address has been recovered. For security we have also sent a password reset email.',
									},
								})
							})
							.catch((error) => {
								console.log('Firebase Error Recover Email Catch', error)
								dispatch({ type: HIDE_NETWORK_ACTIVITY })
								dispatch({
									type: SHOW_ALERT,
									payload: determineAuthErrorMessage(error),
								})
							})
					})
			})
			.catch((error) => {
				console.log('Firebase Error Recover Email Catch', error)
				dispatch({ type: HIDE_NETWORK_ACTIVITY })
				dispatch({
					type: SHOW_ALERT,
					payload: determineAuthErrorMessage(error),
				})
			})
	}
}
export const verifyEmail = (actionCode) => {
	return (dispatch) => {
		dispatch({
			type: SHOW_NETWORK_ACTIVITY,
			payload: 'Verifying Email...',
		})

		firebase
			.auth()
			.applyActionCode(actionCode)
			.then((resp) => {
				console.log('successfully verifyEmail', resp)
				dispatch({ type: HIDE_NETWORK_ACTIVITY })
				//TODO: Update Agent Accounts emailVerified Value
				dispatch({
					type: SHOW_ALERT,
					payload: {
						alertTitle: 'Success',
						alertMessage: 'Your email has been verified.',
					},
				})
			})
			.catch((error) => {
				console.log('Firebase Error Email Verification Catch', error)
				dispatch({ type: HIDE_NETWORK_ACTIVITY })
				dispatch({
					type: SHOW_ALERT,
					payload: determineAuthErrorMessage(error),
				})
			})
	}
}
