const DEVELOPMENT = {
	BASE_URL: 'http://localhost:3001',

	API_BASE_URL: 'https://jdcnu5b6m5.execute-api.us-east-1.amazonaws.com/dev',
	//API_BASE_URL 			: 'http://localhost:5000',

	FIREBASE_API_KEY: 'AIzaSyADk9WqwK_xDqKAmius_9ZSOgWt-MCHQR0',
	FIREBASE_AUTH_DOMAIN: 'fc-tracking-dev.firebaseapp.com',
	FIREBASE_DATABASE_URL: 'https://fc-tracking-dev.firebaseio.com',
	FIREBASE_PROJECT_ID: 'fc-tracking-dev',
	FIREBASE_STORAGE_BUCKET: 'fc-tracking-dev.appspot.com',
	FIREBASE_MESSAGING_SENDER_ID: '1030550009000',

	GOOGLE_MAP_API: 'AIzaSyDWYgiG4NUE8G2rxdOnDJbhSDV0u_Wf5v8',
}
const PRODUCTION = {
	BASE_URL: 'https://www.firstclasseventz.com/',

	API_BASE_URL: 'https://ml3yvv8v56.execute-api.us-east-1.amazonaws.com/prod',

	FIREBASE_API_KEY: 'AIzaSyAsGnO_dMQUZ30eefwUmPTxoJp5z5VLZnI',
	FIREBASE_AUTH_DOMAIN: 'fc-tracking-prod.firebaseapp.com',
	FIREBASE_DATABASE_URL: 'https://fc-tracking-prod.firebaseio.com',
	FIREBASE_PROJECT_ID: 'fc-tracking-prod',
	FIREBASE_STORAGE_BUCKET: 'fc-tracking-prod.appspot.com',
	FIREBASE_MESSAGING_SENDER_ID: '279117579520',

	GOOGLE_MAP_API: 'AIzaSyDWYgiG4NUE8G2rxdOnDJbhSDV0u_Wf5v8',
}

export default {
	// dev: false,
	dev: process.env.NODE_ENV === 'development',
	// apiDebugging: false,
	apiDebugging: process.env.NODE_ENV === 'development',

	VERSION: '0.5.8',

	EMAIL_API_ROUTE: 'https://ov7qju62dl.execute-api.us-east-1.amazonaws.com/development/sendMail',
	GOOGLE_ANALYTICS_KEY: 'UA-109596316-1',

	// ...PRODUCTION,
	...(process.env.NODE_ENV === 'development' ? DEVELOPMENT : PRODUCTION),
}
