import settings from './../config/settings'

//String Validation Functions
//
// - Mostly used for input validation
//
export const isEmail = (email) => {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	//const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //This is left here in case where my expression cleaning was wrong
	return re.test(email)
}

export const isAtLeastLength = (text, number) => {
	return text.length >= number
}

export const requiredCheck = (value) => {
	if (!value) {
		return false
	}

	if (typeof value === 'string') {
		if (value === '') {
			return false
		}
		return true
	}

	if (typeof value === 'object') {
		if (Object.prototype.toString.call(value) === '[object Array]') {
			if (value.length === 0) {
				return false
			}
			return true
		}
		if (Object.keys(value).length === 0 && value.constructor === Object) {
			return false
		}
		return true
	}

	if (typeof value === 'number') {
		return true
	}

	console.log('Required Check NOT HANDLED', value)
	return true
}

export const isPhoneNumber = (phonenumber) => {
	let newNumber = phonenumber
	newNumber = newNumber.replace(/\D/g, '')
	if (newNumber.length < 10 || newNumber.length > 11) {
		return false
	}
	if (newNumber.length === 11) {
		if (newNumber.charAt(0) !== '1') {
			return false
		}
	}

	return true
}

export const isYear = (year) => {
	return String(year).length === 4
}

const isImageFiles = (files) => {
	let valid = true

	for (let i = 0; i < files.length; i++) {
		const file = files[i]
		const fileType = file.type
		const validImageTypes = ['image/jpeg', 'image/png', 'application/pdf']
		if (!validImageTypes.includes(fileType)) {
			valid = false
		}
	}

	return valid
}

export const isImageFile = (file) => {
	if (!file) {
		return false
	}

	if (typeof file === 'object') {
		return isImageFiles(file)
	}

	const fileType = file.type
	const validImageTypes = ['image/jpeg', 'image/png', 'application/pdf']
	if (validImageTypes.includes(fileType)) {
		return true
	}

	return false
}

export const isPercentage = (value) => {
	if (value < 0 || value > 100) {
		return false
	}
	return true
}

export const isInteger = (value) => {
	if (!value) {
		return false
	}

	if (typeof value === 'string') {
		return Number.isInteger(Number(value))
	}

	if (typeof value === 'number') {
		return Number.isInteger(value)
	}

	console.log('Integer check with value not being a string or number')
	return false
}

export const validateInputDataArray = (dataArray) => {
	for (let i = 0; i < dataArray.length; i++) {
		const data = dataArray[i]
		if (!data || (data && !data.valid)) {
			return {
				validation: false,
				validationData: data,
			}
		}
	}
	return { validation: true }
}

export const validateInputData = (inputData, inputLabel) => {
	return {
		alertTitle: `Error - ${inputLabel || inputData.title}`,
		alertMessage: inputData.validLabel,
	}
}

export const compareToVersion = (version, correctVersion) => {
	if (!version || !correctVersion) {
		return {}
	}
	const correctVersionParts = correctVersion.split('.')
	const versionParts = version.split('.')

	const major = Number(correctVersionParts[0]) - Number(versionParts[0])
	const minor = Number(correctVersionParts[1]) - Number(versionParts[1])
	const patch = Number(correctVersionParts[2]) - Number(versionParts[2])

	const behind =
		major > 0 || (major === 0 && minor > 0) || (major === 0 && minor === 0 && patch > 0) //Local version is behind input version
	const equal = major === 0 && minor === 0 && patch === 0 //Local Version is the same as input version
	const ahead =
		major > 0 || (major === 0 && minor < 0) || (major === 0 && minor === 0 && patch < 0) //Local version is ahead of input version - Should never happen for "currentVersion"

	return { behind, equal, ahead }
}
export const compareToLocalVersion = (serverVersion) => {
	const { VERSION: localVersion } = settings
	return compareToVersion(localVersion, serverVersion)
}
