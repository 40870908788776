import { combineReducers } from 'redux'

//Coordinator
import Route from './RouteReducer'
import Circuit from './CircuitReducer'
import Program from './ProgramReducer'

export default combineReducers({
	Route,
	Circuit,
	Program,
})
