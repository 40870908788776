import validate from 'validate.js'
import {
	SHOW_ALERT,
	SHOW_NETWORK_ACTIVITY,
	HIDE_NETWORK_ACTIVITY,
	//HomeKeys,
} from './types'
import firebase from 'firebase/app'
import 'firebase/database'

export const submitEventCode = (data) => {
	const { accessCode, history } = data

	//Validate Data
	const validatorConstraints = {
		accessCode: {
			presence: {
				allowEmpty: false,
			},
		},
	}
	const validationResponse = validate(data, validatorConstraints)
	if (validationResponse) {
		return (dispatch) => {
			dispatch({
				type: SHOW_ALERT,
				payload: {
					alertTitle: 'Error',
					alertMessage: Object.values(validationResponse)[0][0],
				},
			})
		}
	}

	return (dispatch, getState) => {
		dispatch({
			type: SHOW_NETWORK_ACTIVITY,
			payload: 'Checking Access Code...',
		})

		firebase
			.database()
			.ref(`AccessCodes/${accessCode}`)
			.once('value')
			.then((snapshot) => {
				if (snapshot.val()) {
					const { uid, type } = snapshot.val()
					switch (type) {
						case 'route':
							dispatch({ type: HIDE_NETWORK_ACTIVITY })
							history.push(`/tracking/route/${uid}`)
							break
						case 'circuit':
							dispatch({ type: HIDE_NETWORK_ACTIVITY })
							history.push(`/tracking/circuit/${uid}`)
							break
						case 'program':
							dispatch({ type: HIDE_NETWORK_ACTIVITY })
							history.push(`/tracking/program/${uid}`)
							break
						default:
							dispatch({ type: HIDE_NETWORK_ACTIVITY })
							dispatch({
								type: SHOW_ALERT,
								payload: {
									alertTitle: 'Error',
									alertMessage:
										'Invalid code. Check code and try again. Note: the code is case sensitive.',
								},
							})
							break
					}
				} else {
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Error',
							alertMessage: 'Invalid code. Check code and try again.',
						},
					})
				}
			})
			.catch((error) => {
				console.log(error)
				dispatch({ type: HIDE_NETWORK_ACTIVITY })
				dispatch({
					type: SHOW_ALERT,
					payload: {
						alertTitle: 'Error',
						alertMessage: 'Invalid code. Check code and try again.',
					},
				})
			})
	}
}
