import { TrackingKeys } from './../../actions/types'

const INITIAL_STATE = {
	program: undefined,
	programLoaded: false,
	programWatcherRef: undefined,

	programRefresher: undefined,
	refreshRate: 5,

	routes: undefined,
	circuits: undefined,
}

export default (state = INITIAL_STATE, action) => {
	const {
		FETCH_PROGRAM,
		SAVE_PROGRAM_WATCHER,
		REMOVE_PROGRAM_WATCHER,

		SAVE_PROGRAM_REFRESHER,
		SET_PROGRAM_REFRESH_RATE,

		FETCH_PROGRAM_ROUTES,
		FETCH_PROGRAM_CIRCUITS,
	} = TrackingKeys

	switch (action.type) {
		case FETCH_PROGRAM:
			return { ...state, program: action.payload, programLoaded: true }
		case SAVE_PROGRAM_WATCHER:
			return { ...state, programWatcherRef: action.payload }
		case REMOVE_PROGRAM_WATCHER:
			return {
				...state,
				program: INITIAL_STATE.program,
				programLoaded: INITIAL_STATE.programLoaded,
				programWatcherRef: INITIAL_STATE.programWatcherRef,
				programRefresher: INITIAL_STATE.programRefresher,
				refreshRate: INITIAL_STATE.refreshRate,
				routes: INITIAL_STATE.routes,
				circuits: INITIAL_STATE.circuits,
			}

		case SAVE_PROGRAM_REFRESHER:
			return { ...state, programRefresher: action.payload }
		case SET_PROGRAM_REFRESH_RATE:
			return {
				...state,
				refreshRate: action.payload,
			}

		case FETCH_PROGRAM_ROUTES:
			return {
				...state,
				routes: action.payload,
			}
		case FETCH_PROGRAM_CIRCUITS:
			return {
				...state,
				circuits: action.payload,
			}

		default:
			return state
	}
}
