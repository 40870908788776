export const colorBetweenColors = (color1, color2, percentageBetween) => {
    var hex = function(x) {
        x = x.toString(16)
        return x.length === 1 ? '0' + x : x
    }
    const color1RGB = hexToRgb(color1)
    const color2RGB = hexToRgb(color2)

    const r = Math.ceil(color1RGB.r * percentageBetween + color2RGB.r * (1 - percentageBetween))
    const g = Math.ceil(color1RGB.g * percentageBetween + color2RGB.g * (1 - percentageBetween))
    const b = Math.ceil(color1RGB.b * percentageBetween + color2RGB.b * (1 - percentageBetween))

    return '#' + hex(r) + hex(g) + hex(b)
}

function hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
        return r + r + g + g + b + b
    })

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : null
}

const componentToHex = (c) => {
    var hex = c.toString(16)
    return hex.length === 1 ? '0' + hex : hex
}
export const rgbToHex = (r, g, b) => '#' + componentToHex(r) + componentToHex(g) + componentToHex(b)
