import { AuthKeys } from './../actions/types'

const INITIAL_STATE = {
	//Form
	signInEmailData: {
		title: 'Email Address',
		value: '',
		valid: false,
		validLabel: 'Required',
	},
	signInPasswordData: {
		title: 'Password',
		value: '',
		valid: false,
		validLabel: 'Required',
	},

	registerUserTypeData: {
		title: 'User Type',
		value: '',
		valid: false,
		validLabel: 'Required',
	},
	registerEmailData: {
		title: 'Email Address',
		value: '',
		valid: false,
		validLabel: 'Required',
	},
	registerPasswordData: {
		title: 'Password',
		value: '',
		valid: false,
		validLabel: 'Required',
	},
	registerPasswordConfirmData: {
		title: 'Password Confirm',
		value: '',
		valid: false,
		validLabel: 'Required',
	},

	forgotEmailData: {
		title: 'Email Address',
		value: '',
		valid: false,
		validLabel: 'Required',
	},

	resetPasswordData: {
		title: 'New Password',
		value: '',
		valid: false,
		validLabel: 'Required',
	},

	userPermissions: '',
	userPermissionsWatcherRef: undefined,
}

export default (state = INITIAL_STATE, action) => {
	const {
		//Inputs
		SIGN_IN_EMAIL_CHANGED,
		SIGN_IN_PASSWORD_CHANGED,

		REGISTER_USER_TYPE_CHANGED,
		REGISTER_EMAIL_CHANGED,
		REGISTER_PASSWORD_CHANGED,
		REGISTER_PASSWORD_CONFIRM_CHANGED,

		FORGOT_EMAIL_CHANGED,

		RESET_PASSWORD_CHANGED,

		//Requests
		SIGN_IN_USER_SUCCESS,
		SIGN_IN_USER_FAIL,

		SIGN_OUT_USER_SUCCESS,

		REGISTER_USER_SUCCESS,
		REGISTER_USER_FAIL,

		RESET_PASSWORD_SUCCESS,
		RESET_PASSWORD_FAIL,

		SEND_FORGOT_PASSWORD_EMAIL_SUCCESS,
		SEND_FORGOT_PASSWORD_EMAIL_FAIL,

		FETCH_USER_PERMISSIONS,
		SAVE_USER_TYPE_WATCHER,
		REMOVE_USER_TYPE_WATCHER,
	} = AuthKeys

	switch (action.type) {
		//Inputs
		case SIGN_IN_EMAIL_CHANGED:
			return { ...state, signInEmailData: { ...state.signInEmailData, ...action.payload } }
		case SIGN_IN_PASSWORD_CHANGED:
			return {
				...state,
				signInPasswordData: { ...state.signInPasswordData, ...action.payload },
			}
		case REGISTER_USER_TYPE_CHANGED:
			return {
				...state,
				registerUserTypeData: { ...state.registerUserTypeData, ...action.payload },
			}
		case REGISTER_EMAIL_CHANGED:
			return {
				...state,
				registerEmailData: { ...state.registerEmailData, ...action.payload },
			}
		case REGISTER_PASSWORD_CHANGED:
			return {
				...state,
				registerPasswordData: { ...state.registerPasswordData, ...action.payload },
			}
		case REGISTER_PASSWORD_CONFIRM_CHANGED:
			return {
				...state,
				registerPasswordConfirmData: {
					...state.registerPasswordConfirmData,
					...action.payload,
				},
			}
		case FORGOT_EMAIL_CHANGED:
			return { ...state, forgotEmailData: { ...state.forgotEmailData, ...action.payload } }
		case RESET_PASSWORD_CHANGED:
			return {
				...state,
				resetPasswordData: { ...state.resetPasswordData, ...action.payload },
			}

		//Requests
		case SIGN_IN_USER_SUCCESS:
			return {
				...state,
				signInEmailData: INITIAL_STATE.signInEmailData,
				signInPasswordData: INITIAL_STATE.signInPasswordData,
			}
		case SIGN_IN_USER_FAIL:
			return { ...state, signInPasswordData: INITIAL_STATE.signInPasswordData }
		case SIGN_OUT_USER_SUCCESS:
			return INITIAL_STATE

		case REGISTER_USER_SUCCESS:
			return {
				...state,
				registerEmailData: INITIAL_STATE.registerEmailData,
				registerPasswordData: INITIAL_STATE.registerPasswordData,
				registerPasswordConfirmData: INITIAL_STATE.registerPasswordConfirmData,
			}
		case REGISTER_USER_FAIL:
			return {
				...state,
				registerPasswordData: INITIAL_STATE.registerPasswordData,
				registerPasswordConfirmData: INITIAL_STATE.registerPasswordConfirmData,
			}

		case RESET_PASSWORD_SUCCESS:
			return { ...state, resetPasswordData: INITIAL_STATE.resetPasswordData }
		case RESET_PASSWORD_FAIL:
			return { ...state, resetPasswordData: INITIAL_STATE.resetPasswordData }

		case SEND_FORGOT_PASSWORD_EMAIL_SUCCESS:
			return { ...state, forgotEmailData: INITIAL_STATE.forgotEmailData }
		case SEND_FORGOT_PASSWORD_EMAIL_FAIL:
			return { ...state, forgotEmailData: INITIAL_STATE.forgotEmailData }

		case FETCH_USER_PERMISSIONS:
			return { ...state, userPermissions: action.payload }
		case SAVE_USER_TYPE_WATCHER:
			return { ...state, userPermissionsWatcherRef: action.payload }
		case REMOVE_USER_TYPE_WATCHER:
			return { ...state, userPermissionsWatcherRef: INITIAL_STATE.userTypeWatcher }

		default:
			return state
	}
}
