import { combineReducers } from 'redux'

import Global from './GlobalReducer'
import Loading from './LoadingReducer'
import Auth from './AuthReducer'

import Home from './HomeReducer'
import Admin from './AdminReducer'

import Coordinator from './Coordinator'
import Tracking from './Tracking'

export default combineReducers({
	Global,
	Loading,
	Auth,
	Home,
	Admin,

	//Combined Reducers
	Tracking,
	Coordinator,
})
