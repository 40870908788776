import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { unregister } from './registerServiceWorker'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import ReduxThunk from 'redux-thunk'
import { StyleRoot } from 'radium'
import { withScriptjs } from 'react-google-maps'

import 'react-table/react-table.css'
import './reactPowerSelect.css'
import 'react-datepicker/dist/react-datepicker.css'
import './index.css'

import ScrollToTop from './component/ScrollToTop'

import GATracking from './component/GATracking'
import App from './App'
import settings from './config/settings'
import reducers from './reducers'

const store = createStore(reducers, applyMiddleware(ReduxThunk))

const GoogleMapsAPIWrapper = withScriptjs((props) => props.children)

const element = (
	<Suspense fallback={<div />}>
		<GoogleMapsAPIWrapper
			googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${
				settings.GOOGLE_MAP_API
			}&v=3.exp&libraries=geometry,drawing,places`}
			loadingElement={<div />}
		>
			<StyleRoot>
				<Provider store={store}>
					<Router>
						<ScrollToTop>
							<Route component={GATracking(App)} />
						</ScrollToTop>
					</Router>
				</Provider>
			</StyleRoot>
		</GoogleMapsAPIWrapper>
	</Suspense>
)

ReactDOM.render(element, document.getElementById('root'))
unregister()
