import React, { Component, Fragment, lazy } from 'react'
import { Route, withRouter, Switch } from 'react-router-dom'
import firebase from 'firebase/app'
import 'firebase/auth'
import { connect } from 'react-redux'
import ifvisible from 'ifvisible'

import {
	hideAlert,
	hideConfirm,
	setUserActive,
	setUserInactive,
	startWatchingAppVersion,
	startLoadingProcess,
} from './../actions'

import settings from './../config/settings'

//Global Components
import TEAlert from './../component/Popup/TEAlert'
import TEConfirm from './../component/Popup/TEConfirm'
import TENetworkActivity from './../component/Popup/TENetworkActivity'

const FrontContentWrapper = lazy(() => import('./../layout/Front/FrontContentWrapper'))
const TrackingContentWrapper = lazy(() => import('./../layout/Tracking/TrackingContentWrapper'))
const PortalContentWrapper = lazy(() => import('./../layout/Portal/PortalContentWrapper'))
const AdminContentWrapper = lazy(() => import('./../layout/Admin/AdminContentWrapper'))

class App extends Component {
	componentDidMount() {
		const {
			FIREBASE_API_KEY,
			FIREBASE_AUTH_DOMAIN,
			FIREBASE_DATABASE_URL,
			FIREBASE_PROJECT_ID,
			FIREBASE_STORAGE_BUCKET,
			FIREBASE_MESSAGING_SENDER_ID,
		} = settings
		const {
			startLoadingProcess,
			startWatchingAppVersion,
			setUserActive,
			setUserInactive,
		} = this.props

		firebase.initializeApp({
			apiKey: FIREBASE_API_KEY,
			authDomain: FIREBASE_AUTH_DOMAIN,
			databaseURL: FIREBASE_DATABASE_URL,
			projectId: FIREBASE_PROJECT_ID,
			storageBucket: FIREBASE_STORAGE_BUCKET,
			messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
		})
		startLoadingProcess()
		startWatchingAppVersion()

		//Monitor User Activity
		ifvisible.setIdleDuration(600) //10 Minutes Idle Time
		ifvisible.wakeup(() => {
			setUserActive()
		})
		ifvisible.idle(() => {
			setUserInactive()
		})
	}

	//Alert
	handleConfirmClose = () => {
		this.props.hideConfirm()
	}
	handleAlertClose = () => {
		this.props.hideAlert()
	}

	render() {
		const {
			confirmTitle,
			confirmMessage,
			confirmIsVisible,
			confirmLeftTitle,
			confirmRightOnClick,
			confirmRightTitle,
			alertIsVisible,
			alertTitle,
			alertMessage,
			networkActivityIsVisible,
			networkMessage,
			userCheckComplete,
			appVersions,
			appVersionsLoaded,
			location,
		} = this.props

		if (!userCheckComplete || !appVersionsLoaded) {
			return null
		}

		return (
			<Fragment>
				<Switch>
					<Route
						path="/admin/"
						render={() => <AdminContentWrapper appVersions={appVersions} />}
					/>
					<Route
						path="/portal/"
						render={() => <PortalContentWrapper appVersions={appVersions} />}
					/>
					<Route
						path="/tracking/"
						render={() => <TrackingContentWrapper appVersions={appVersions} />}
					/>
					<Route path="/" render={() => <FrontContentWrapper location={location} />} />
				</Switch>

				<TEConfirm
					title={confirmTitle}
					message={confirmMessage}
					visible={confirmIsVisible}
					leftOnClick={this.handleConfirmClose}
					leftButtonTitle={confirmLeftTitle}
					rightOnClick={confirmRightOnClick}
					rightButtonTitle={confirmRightTitle}
				/>
				<TEAlert
					title={alertTitle}
					message={alertMessage}
					visible={alertIsVisible}
					onClick={this.handleAlertClose}
				/>
				<TENetworkActivity message={networkMessage} visible={networkActivityIsVisible} />
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => {
	const {
		confirmTitle,
		confirmMessage,
		confirmIsVisible,
		confirmLeftTitle,
		confirmRightOnClick,
		confirmRightTitle,
		alertIsVisible,
		alertTitle,
		alertMessage,
		networkActivityIsVisible,
		networkMessage,
	} = state.Global

	//
	//NOTE:
	//
	//  - userCheckComplete is used to allow for firebase to
	//      figure out of there is a user already signed in or
	//      not. Because this is async we need to wait to render
	//      all the routes on the page for a split second before
	//      letting react router figure out if it should redirect
	//      from a protected page.
	//
	//  - This should probably be moved to a nested place where
	//      all portal content is held. This way we aren't doing
	//      this check for the front pages.
	//
	const { userCheckComplete, appVersions, appVersionsLoaded } = state.Loading

	return {
		confirmTitle,
		confirmMessage,
		confirmIsVisible,
		confirmLeftTitle,
		confirmRightOnClick,
		confirmRightTitle,
		alertIsVisible,
		alertTitle,
		alertMessage,
		networkActivityIsVisible,
		networkMessage,
		userCheckComplete,
		appVersions,
		appVersionsLoaded,
	}
}

export default withRouter(
	connect(
		mapStateToProps,
		{
			hideAlert,
			hideConfirm,
			setUserActive,
			setUserInactive,
			startWatchingAppVersion,
			startLoadingProcess,
		}
	)(App)
)
