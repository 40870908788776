import {
	//SHOW_ALERT,
	SHOW_NETWORK_ACTIVITY,
	HIDE_NETWORK_ACTIVITY,
	LoadingKeys,
	CoordinatorKeys,
	AuthKeys,
} from './types'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

// import { unregister } from './../registerServiceWorker'
// import { compareToLocalVersion } from './../helpers'

import { startWatchingCoordinator, stopWatchingCoordinator } from './Coordinator/CoordinatorActions'

export const startWatchingAppVersion = () => {
	return (dispatch) => {
		firebase
			.database()
			.ref(`Versions`)
			.on(
				'value',
				(snapshot) => {
					// if (snapshot.val()) {
					// 	const { minimumVersion, currentVersion } = snapshot.val()
					// 	if (minimumVersion) {
					// 		const { behind } = compareToLocalVersion(minimumVersion)
					// 		if (behind) {
					// 			// console.log(
					// 			// 	'Attempting to unregister Service worker due to being behind minimumVersion'
					// 			// )
					// 			unregister()
					// 		}
					// 	}
					// 	if (currentVersion) {
					// 		const { behind } = compareToLocalVersion(currentVersion)
					// 		if (behind) {
					// 			// console.log(
					// 			// 	'Attempting to unregister Service worker due to being behind currentVersion'
					// 			// )
					// 			unregister()
					// 		}
					// 	}
					// }

					dispatch({
						type: LoadingKeys.FETCH_APP_VERSIONS,
						payload: snapshot.val(),
					})
				},
				(error) => console.log(error)
			)
	}
}

//
//User Permissions
//
export const startWatchingUserPermissions = (dispatch, getState) => {
	//Load User Type
	const { uid } = firebase.auth().currentUser
	const userPermissionsWatcherRef = firebase.database().ref(`UserPermissions/${uid}`)
	userPermissionsWatcherRef.on(
		'value',
		(snapshot) => {
			dispatch({
				type: AuthKeys.FETCH_USER_PERMISSIONS,
				payload: snapshot.val(),
			})

			const userPermissions = snapshot.val()
			if (userPermissions.admin) {
				//*******************//
				// COMPLETED LOADING //
				//*******************//
				// - UserPermissions Loaded
				//
				const { userCheckComplete } = getState().Loading
				if (!userCheckComplete) {
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({ type: LoadingKeys.USER_CHECK_COMPLETE })
				}
			} else if (userPermissions.coordinator) {
				startWatchingCoordinator(dispatch, getState)

				firebase
					.database()
					.ref(`Coordinators/${uid}`)
					.once('value')
					.then((snapshot) => {
						dispatch({
							type: CoordinatorKeys.FETCH_COORDINATOR,
							payload: snapshot.val(),
						})

						//*******************//
						// COMPLETED LOADING //
						//*******************//
						// - UserPermissions Loaded
						// - Loaded Coordinator
						//
						const { userCheckComplete } = getState().Loading
						if (!userCheckComplete) {
							dispatch({ type: HIDE_NETWORK_ACTIVITY })
							dispatch({ type: LoadingKeys.USER_CHECK_COMPLETE })
						}
					})
					.catch((error) => console.log(error))
			} else {
				//*******************//
				// COMPLETED LOADING //
				//*******************//
				// - UserPermissions Loaded
				// - NEITHER ADMIN OR COORDINATOR!?!?
				//
				const { userCheckComplete } = getState().Loading
				if (!userCheckComplete) {
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({ type: LoadingKeys.USER_CHECK_COMPLETE })
				}
			}
		},
		(error) => console.log(error)
	)
	dispatch({
		type: AuthKeys.SAVE_USER_PERMISSIONS_WATCHER,
		payload: userPermissionsWatcherRef,
	})
}
export const stopWatchingUserPermissions = (dispatch, getState) => {
	const { userPermissionsWatcherRef } = getState().Auth
	if (userPermissionsWatcherRef) {
		userPermissionsWatcherRef.off()
		dispatch({ type: AuthKeys.REMOVE_USER_PERMISSIONS_WATCHER })
	}
}

//
// THIS STARTS OFF THE LOADING PROCESS.
//
export const startLoadingProcess = () => {
	return (dispatch, getState) => {
		//Questionable if we want this here. Turning it off for now. This should be so quick that it doesn't matter.
		dispatch({
			type: SHOW_NETWORK_ACTIVITY,
			payload: 'Loading...',
		})

		//
		//USED FOR WORKING ON THE SITE OFFLINE
		//
		//dispatch({ type: HIDE_NETWORK_ACTIVITY })
		//dispatch({ type: LoadingKeys.USER_CHECK_COMPLETE})
		firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				//console.log("USER SIGNED IN. START WATCHERS!")
				dispatch({ type: LoadingKeys.USER_CHECK_STARTED })
				startWatchingUserPermissions(dispatch, getState)
			} else {
				//*******************//
				// COMPLETED LOADING //
				//*******************//
				// - UserPermissions Failed To Load -> Not Signed In
				//
				const { userCheckComplete } = getState().Loading
				if (!userCheckComplete) {
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({ type: LoadingKeys.USER_CHECK_COMPLETE })
				}

				//console.log("USER SIGNED OUT. STOP WATCHERS!")
				stopWatchingUserPermissions(dispatch, getState)
				stopWatchingCoordinator(dispatch, getState)
			}
		})
	}
}
