import { CoordinatorKeys } from './../../actions/types'

const INITIAL_STATE = {
	coordinatorModel: undefined,
	coordinatorWatcherRef: undefined,

	organization: undefined,
	organizationLoaded: false,
	organizationWatcherRef: undefined,
}

export default (state = INITIAL_STATE, action) => {
	const {
		FETCH_COORDINATOR,
		SAVE_COORDINATOR_WATCHER,
		REMOVE_COORDINATOR_WATCHER,

		FETCH_ORGANIZATION,
		SAVE_ORGANIZATION_WATCHER,
		REMOVE_ORGANIZATION_WATCHER,
	} = CoordinatorKeys

	switch (action.type) {
		case FETCH_COORDINATOR:
			return { ...state, coordinatorModel: action.payload, coordinatorModelLoaded: true }
		case SAVE_COORDINATOR_WATCHER:
			return { ...state, coordinatorWatcherRef: action.payload }
		case REMOVE_COORDINATOR_WATCHER:
			return {
				...state,
				coordinatorModel: INITIAL_STATE.coordinatorModel,
				coordinatorModelLoaded: INITIAL_STATE.coordinatorModelLoaded,
				coordinatorWatcherRef: INITIAL_STATE.coordinatorWatcherRef,
			}

		case FETCH_ORGANIZATION:
			return { ...state, organization: action.payload, organizationLoaded: true }
		case SAVE_ORGANIZATION_WATCHER:
			return { ...state, organizationWatcherRef: action.payload }
		case REMOVE_ORGANIZATION_WATCHER:
			return {
				...state,
				organization: INITIAL_STATE.organization,
				organizationLoaded: INITIAL_STATE.organizationLoaded,
				organizationWatcherRef: INITIAL_STATE.organizationWatcherRef,
			}

		default:
			return state
	}
}
