import { CoordinatorKeys } from './../../actions/types'

const INITIAL_STATE = {
	programsLive: undefined,
	programsLiveLoaded: false,
	programsLiveWatcherRef: undefined,

	programsHistory: undefined,
	programsHistoryLoaded: false,
	programsHistoryWatcherRef: undefined,

	program: undefined,
	programLoaded: false,
	programWatcherRef: undefined,

	programRefresher: undefined,
	refreshRate: 5,

	routesLive: undefined,
	routesHistory: undefined,
	circuitsLive: undefined,
	circuitsHistory: undefined,
}

export default (state = INITIAL_STATE, action) => {
	const {
		FETCH_PROGRAMS_LIVE,
		SAVE_PROGRAMS_LIVE_WATCHER,
		REMOVE_PROGRAMS_LIVE_WATCHER,

		FETCH_PROGRAMS_HISTORY,
		SAVE_PROGRAMS_HISTORY_WATCHER,
		REMOVE_PROGRAMS_HISTORY_WATCHER,

		FETCH_PROGRAM,
		SAVE_PROGRAM_WATCHER,
		REMOVE_PROGRAM_WATCHER,

		SAVE_PROGRAM_REFRESHER,
		SET_PROGRAM_REFRESH_RATE,

		FETCH_PROGRAM_ROUTES,
		FETCH_PROGRAM_CIRCUITS,
	} = CoordinatorKeys

	switch (action.type) {
		case FETCH_PROGRAMS_LIVE:
			return { ...state, programsLive: action.payload, programsLiveLoaded: true }
		case SAVE_PROGRAMS_LIVE_WATCHER:
			return { ...state, programsLiveWatcherRef: action.payload }
		case REMOVE_PROGRAMS_LIVE_WATCHER:
			return {
				...state,
				programsLive: INITIAL_STATE.programsLive,
				programsLiveLoaded: INITIAL_STATE.programsLiveLoaded,
				programsLiveWatcherRef: INITIAL_STATE.programsLiveWatcherRef,
			}

		case FETCH_PROGRAMS_HISTORY:
			return { ...state, programsHistory: action.payload, programsHistoryLoaded: true }
		case SAVE_PROGRAMS_HISTORY_WATCHER:
			return { ...state, programsHistoryWatcherRef: action.payload }
		case REMOVE_PROGRAMS_HISTORY_WATCHER:
			return {
				...state,
				programsHistory: INITIAL_STATE.programsHistory,
				programsHistoryLoaded: INITIAL_STATE.programsHistoryLoaded,
				programsHistoryWatcherRef: INITIAL_STATE.programsHistoryWatcherRef,
			}

		case FETCH_PROGRAM:
			return { ...state, program: action.payload, programLoaded: true }
		case SAVE_PROGRAM_WATCHER:
			return { ...state, programWatcherRef: action.payload }
		case REMOVE_PROGRAM_WATCHER:
			return {
				...state,
				program: INITIAL_STATE.program,
				programLoaded: INITIAL_STATE.programLoaded,
				programWatcherRef: INITIAL_STATE.programWatcherRef,
				programRefresher: INITIAL_STATE.programRefresher,
				refreshRate: INITIAL_STATE.refreshRate,
				routes: INITIAL_STATE.routes,
				circuits: INITIAL_STATE.circuits,
			}

		case SAVE_PROGRAM_REFRESHER:
			return { ...state, programRefresher: action.payload }
		case SET_PROGRAM_REFRESH_RATE:
			return {
				...state,
				refreshRate: action.payload,
			}

		case FETCH_PROGRAM_ROUTES: {
			const { routesLive, routesHistory } = action.payload
			return {
				...state,
				routesLive,
				routesHistory,
			}
		}
		case FETCH_PROGRAM_CIRCUITS: {
			const { circuitsLive, circuitsHistory } = action.payload
			return {
				...state,
				circuitsLive,
				circuitsHistory,
			}
		}

		default:
			return state
	}
}
