import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'

import settings from './../config/settings'

export const apiRequest = (method, path, data, withToken = true) => {
	const { apiDebugging } = settings

	const { API_BASE_URL } = settings

	const url = API_BASE_URL + '/' + path

	if (withToken) {
		const { uid } = firebase.auth().currentUser
		return firebase
			.auth()
			.currentUser.getIdToken()
			.then((token) => {
				const headers = { uid, token }

				if (apiDebugging) {
					console.log('API REQUEST:', { method, url, headers, data })
				}
				return axios({ method, url, headers, data }).then((response) => {
					if (apiDebugging) {
						console.log(response)
					}
					return response
				})
			})
	}

	if (apiDebugging) {
		console.log('API REQUEST:', { method, url, data })
	}
	return axios({ method, url, data }).then((response) => {
		if (apiDebugging) {
			console.log(response)
		}
		return response
	})
}

export const apiCatchAlert = ({ error, title: defaultTitle, message: defaultMessage }) => {
	const { dev } = settings

	if (dev) {
		console.log(error)
	}

	if (error && error.response && error.response.data) {
		const { title = defaultTitle, message = defaultMessage } = error.response.data

		return {
			alertTitle: title,
			alertMessage: message,
		}
	}

	return {
		alertTitle: defaultTitle,
		alertMessage: defaultMessage,
	}
}
