import { CoordinatorKeys } from './../../actions/types'

const INITIAL_STATE = {
	routesLive: undefined,
	routesLiveLoaded: false,
	routesLiveWatcherRef: undefined,

	routesHistory: undefined,
	routesHistoryLoaded: false,
	routesHistoryWatcherRef: undefined,

	route: undefined,
	routeLoaded: false,
	routeWatcherRef: undefined,

	routeLogs: undefined,
	routeLogsLoaded: false,
	routeLogsWatcherRef: undefined,
}

export default (state = INITIAL_STATE, action) => {
	const {
		FETCH_ROUTES_LIVE,
		SAVE_ROUTES_LIVE_WATCHER,
		REMOVE_ROUTES_LIVE_WATCHER,

		FETCH_ROUTES_HISTORY,
		SAVE_ROUTES_HISTORY_WATCHER,
		REMOVE_ROUTES_HISTORY_WATCHER,

		FETCH_ROUTE,
		SAVE_ROUTE_WATCHER,
		REMOVE_ROUTE_WATCHER,

		FETCH_ROUTE_LOGS,
		SAVE_ROUTE_LOGS_WATCHER,
		REMOVE_ROUTE_LOGS_WATCHER,
	} = CoordinatorKeys

	switch (action.type) {
		case FETCH_ROUTES_LIVE:
			return { ...state, routesLive: action.payload, routesLiveLoaded: true }
		case SAVE_ROUTES_LIVE_WATCHER:
			return { ...state, routesLiveWatcherRef: action.payload }
		case REMOVE_ROUTES_LIVE_WATCHER:
			return {
				...state,
				routesLive: INITIAL_STATE.routesLive,
				routesLiveLoaded: INITIAL_STATE.routesLiveLoaded,
				routesLiveWatcherRef: INITIAL_STATE.routesLiveWatcherRef,
			}

		case FETCH_ROUTES_HISTORY:
			return { ...state, routesHistory: action.payload, routesHistoryLoaded: true }
		case SAVE_ROUTES_HISTORY_WATCHER:
			return { ...state, routesHistoryWatcherRef: action.payload }
		case REMOVE_ROUTES_HISTORY_WATCHER:
			return {
				...state,
				routesHistory: INITIAL_STATE.routesHistory,
				routesHistoryLoaded: INITIAL_STATE.routesHistoryLoaded,
				routesHistoryWatcherRef: INITIAL_STATE.routesHistoryWatcherRef,
			}

		case FETCH_ROUTE: {
			return { ...state, route: action.payload, routeLoaded: true }
		}
		case SAVE_ROUTE_WATCHER:
			return { ...state, routeWatcherRef: action.payload }
		case REMOVE_ROUTE_WATCHER:
			return {
				...state,
				route: INITIAL_STATE.route,
				routeLoaded: INITIAL_STATE.routeLoaded,
				routeWatcherRef: INITIAL_STATE.routeWatcherRef,
			}

		case FETCH_ROUTE_LOGS: {
			return { ...state, routeLogs: action.payload, routeLogsLoaded: true }
		}
		case SAVE_ROUTE_LOGS_WATCHER:
			return { ...state, routeLogsWatcherRef: action.payload }
		case REMOVE_ROUTE_LOGS_WATCHER:
			return {
				...state,
				routeLogs: INITIAL_STATE.route,
				routeLogsLoaded: INITIAL_STATE.routeLoaded,
				routeLogsWatcherRef: INITIAL_STATE.routeWatcherRef,
			}

		default:
			return state
	}
}
