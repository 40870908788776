import validate from 'validate.js'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
//import { push } from 'react-router-redux'

import { SHOW_ALERT, SHOW_NETWORK_ACTIVITY, HIDE_NETWORK_ACTIVITY, AdminKeys } from './types'
import { determineAuthErrorMessage, apiRequest } from './../helpers'
import settings from './../config/settings'

//
//Organizations
//
export const startWatchingOrganizations = () => {
	const { FETCH_ORGANIZATIONS, SAVE_ORGANIZATIONS_WATCHER } = AdminKeys
	return (dispatch) => {
		const organizationsWatcherRef = firebase.database().ref(`CoordinatorOrganizations`)
		organizationsWatcherRef.on(
			'value',
			(snapshot) => {
				dispatch({
					type: FETCH_ORGANIZATIONS,
					payload: snapshot.val(),
				})
			},
			(error) => console.log(error)
		)

		dispatch({
			type: SAVE_ORGANIZATIONS_WATCHER,
			payload: organizationsWatcherRef,
		})
	}
}
export const stopWatchingOrganizations = () => {
	return (dispatch, getState) => {
		const { organizationsWatcherRef } = getState().Admin
		if (organizationsWatcherRef) {
			organizationsWatcherRef.off()
			dispatch({ type: AdminKeys.REMOVE_ORGANIZATIONS_WATCHER })
		}
	}
}

export const createOrganization = (data) => {
	const { name } = data

	//Validate Data
	const validatorConstraints = {
		name: {
			presence: {
				allowEmpty: false,
			},
		},
	}
	const validationResponse = validate(data, validatorConstraints)
	if (validationResponse) {
		return (dispatch) =>
			new Promise((res, rej) => {
				dispatch({
					type: SHOW_ALERT,
					payload: {
						alertTitle: 'Error',
						alertMessage: Object.values(validationResponse)[0][0],
					},
				})
				return rej()
			})
	}

	return (dispatch) =>
		new Promise((res, rej) => {
			dispatch({
				type: SHOW_NETWORK_ACTIVITY,
				payload: 'Creating Organization...',
			})

			firebase
				.database()
				.ref(`CoordinatorOrganizations`)
				.push({
					name,
					dateCreated: new Date().getTime(),
				})
				.then((response) => {
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Success',
							alertMessage: 'Organization Created.',
						},
					})
					return res()
				})
				.catch((error) => {
					console.log(error)
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Error',
							alertMessage: 'Error Creating Organization.',
						},
					})
					return rej()
				})
		})
}

export const watchOrganization = (uid) => {
	const { FETCH_ORGANIZATION, SAVE_ORGANIZATION_WATCHER } = AdminKeys

	return (dispatch) => {
		const organizationWatcherRef = firebase.database().ref(`CoordinatorOrganizations/${uid}`)
		organizationWatcherRef.on(
			'value',
			(snapshot) => {
				dispatch({
					type: FETCH_ORGANIZATION,
					payload: snapshot.val(),
				})
			},
			(error) => console.log(error)
		)

		dispatch({
			type: SAVE_ORGANIZATION_WATCHER,
			payload: organizationWatcherRef,
		})
	}
}
export const stopWatchingOrganization = () => {
	return (dispatch, getState) => {
		const { REMOVE_ORGANIZATION_WATCHER } = AdminKeys
		const { organizationWatcherRef } = getState().Admin
		if (organizationWatcherRef) {
			organizationWatcherRef.off()
			dispatch({ type: REMOVE_ORGANIZATION_WATCHER })
		}
	}
}

//TODO:
//
// 1. Add Ability to edit organization information (currently just name)
// 2. Enable the ability to track organization pricing plan, credit card,
//		contact and other information
//
//
/*export const saveOrganizationDetails = (data) => {

}*/

export const watchCoordinators = (uid) => {
	const { FETCH_COORDINATORS, SAVE_COORDINATORS_WATCHER } = AdminKeys

	return (dispatch) => {
		const coordinatorsWatcherRef = firebase
			.database()
			.ref(`Coordinators`)
			.orderByChild('organizationUID')
			.equalTo(uid)
		coordinatorsWatcherRef.on(
			'value',
			(snapshot) => {
				dispatch({
					type: FETCH_COORDINATORS,
					payload: snapshot.val(),
				})
			},
			(error) => console.log(error)
		)

		dispatch({
			type: SAVE_COORDINATORS_WATCHER,
			payload: coordinatorsWatcherRef,
		})
	}
}
export const stopWatchingCoordinators = () => {
	return (dispatch, getState) => {
		const { REMOVE_COORDINATORS_WATCHER } = AdminKeys
		const { coordinatorsWatcherRef } = getState().Admin
		if (coordinatorsWatcherRef) {
			coordinatorsWatcherRef.off()
			dispatch({ type: REMOVE_COORDINATORS_WATCHER })
		}
	}
}

export const addCoordinator = (data) => {
	//
	//TODO:
	//
	// 1. Add an email to the new users informing
	//		them of their account being created
	//

	const { firstName, lastName, email, password, organizationUID } = data

	//Validate Data
	const validatorConstraints = {
		firstName: {
			presence: {
				allowEmpty: false,
			},
		},
		lastName: {
			presence: {
				allowEmpty: false,
			},
		},
		email: {
			presence: {
				allowEmpty: false,
			},
			email: true,
		},
		password: {
			presence: {
				allowEmpty: false,
			},
			length: { minimum: 6 },
		},
		organizationUID: {
			presence: {
				allowEmpty: false,
			},
		},
	}
	const validationResponse = validate(data, validatorConstraints)
	if (validationResponse) {
		return (dispatch) =>
			new Promise((res, rej) => {
				dispatch({
					type: SHOW_ALERT,
					payload: {
						alertTitle: 'Error',
						alertMessage: Object.values(validationResponse)[0][0],
					},
				})
				return rej()
			})
	}
	return (dispatch) => {
		return new Promise((res, rej) => {
			dispatch({
				type: SHOW_NETWORK_ACTIVITY,
				payload: 'Adding Coordinator...',
			})

			//Setup Secondary FB so that it doesn't sign out current user.
			const {
				FIREBASE_API_KEY,
				FIREBASE_AUTH_DOMAIN,
				FIREBASE_DATABASE_URL,
				FIREBASE_PROJECT_ID,
				FIREBASE_STORAGE_BUCKET,
				FIREBASE_MESSAGING_SENDER_ID,
			} = settings

			const secondaryApp = firebase.initializeApp(
				{
					apiKey: FIREBASE_API_KEY,
					authDomain: FIREBASE_AUTH_DOMAIN,
					databaseURL: FIREBASE_DATABASE_URL,
					projectId: FIREBASE_PROJECT_ID,
					storageBucket: FIREBASE_STORAGE_BUCKET,
					messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
				},
				'secondary'
			)

			secondaryApp
				.auth()
				.createUserWithEmailAndPassword(email, password)
				.then((response) => {
					const { uid } = secondaryApp.auth().currentUser

					//Save User Permission Data
					const permissionsData = {
						coordinator: true,
					}
					const userPermissionRequest = firebase
						.database()
						.ref(`UserPermissions/${uid}`)
						.set(permissionsData)

					//Save Base Profile Information
					const coordinatorData = {
						dateCreated: new Date().getTime(),
						email: email,
						firstName: firstName,
						lastName: lastName,
						displayName: `${firstName} ${lastName}`.trim(),
						accountActive: 'Active',
						organizationUID,
					}
					const coordinatorDataRequest = firebase
						.database()
						.ref(`Coordinators/${uid}`)
						.set(coordinatorData)

					//Update Organization
					const organizationDataRequest = firebase
						.database()
						.ref(`CoordinatorOrganizations/${organizationUID}/coordinators/${uid}`)
						.set(true)

					return Promise.all([
						userPermissionRequest,
						coordinatorDataRequest,
						organizationDataRequest,
					])
				})
				.then((responses) => {
					return secondaryApp.auth().signOut()
				})
				.then(() => {
					secondaryApp.delete()
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Success',
							alertMessage: 'Coordinator Successfully Added.',
						},
					})
					return res()
				})
				.catch((error) => {
					secondaryApp.delete()
					console.log(error)
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: determineAuthErrorMessage(error),
					})
					return rej()
				})
		})
	}
}

export const watchCoordinator = (uid) => {
	const { FETCH_COORDINATOR, SAVE_COORDINATOR_WATCHER } = AdminKeys

	return (dispatch) => {
		const coordinatorWatcherRef = firebase.database().ref(`Coordinators/${uid}`)
		coordinatorWatcherRef.on(
			'value',
			(snapshot) => {
				dispatch({
					type: FETCH_COORDINATOR,
					payload: snapshot.val(),
				})
			},
			(error) => console.log(error)
		)

		dispatch({
			type: SAVE_COORDINATOR_WATCHER,
			payload: coordinatorWatcherRef,
		})
	}
}
export const stopWatchingCoordinator = () => {
	return (dispatch, getState) => {
		const { REMOVE_COORDINATOR_WATCHER } = AdminKeys
		const { coordinatorWatcherRef } = getState().Admin
		if (coordinatorWatcherRef) {
			coordinatorWatcherRef.off()
			dispatch({ type: REMOVE_COORDINATOR_WATCHER })
		}
	}
}

export const saveCoordinatorDetails = (data) => {
	const { firstName, lastName, active, uid } = data

	//Validate Data
	const validatorConstraints = {
		firstName: {
			presence: {
				allowEmpty: false,
			},
		},
		lastName: {
			presence: {
				allowEmpty: false,
			},
		},
		active: {
			presence: {
				allowEmpty: false,
			},
		},
		uid: {
			presence: {
				allowEmpty: false,
			},
		},
	}
	const validationResponse = validate(data, validatorConstraints)
	if (validationResponse) {
		return (dispatch) => {
			dispatch({
				type: SHOW_ALERT,
				payload: {
					alertTitle: 'Error',
					alertMessage: Object.values(validationResponse)[0][0],
				},
			})
		}
	}

	return (dispatch) => {
		dispatch({
			type: SHOW_NETWORK_ACTIVITY,
			payload: 'Saving Coordinator Details...',
		})
		firebase
			.database()
			.ref(`Coordinators/${uid}`)
			.update({
				firstName,
				lastName,
				displayName: `${firstName} ${lastName}`.trim(),
				accountActive: active,
			})
			.then(() => {
				dispatch({ type: HIDE_NETWORK_ACTIVITY })
				dispatch({
					type: SHOW_ALERT,
					payload: {
						alertTitle: 'Success',
						alertMessage: 'Coordinator Details Saved.',
					},
				})
			})
			.catch((error) => {
				console.log(error)
				dispatch({ type: HIDE_NETWORK_ACTIVITY })
				dispatch({
					type: SHOW_ALERT,
					payload: {
						alertTitle: 'Error',
						alertMessage: 'Error Saving Coordinator Details.',
					},
				})
			})
	}
}

export const startCircuitApiTest = (data) => {
	//
	//TODO:
	//
	// - Update test to full circuit route
	//
	const { circuitUID, circuitWaitTime } = data

	//Validate Data
	const validatorConstraints = {
		circuitUID: {
			presence: {
				allowEmpty: false,
			},
		},
		circuitWaitTime: {
			presence: {
				allowEmpty: false,
			},
			numericality: {
				onlyInteger: true,
				greaterThan: 10,
			},
		},
	}
	const validationResponse = validate(data, validatorConstraints)
	if (validationResponse) {
		return (dispatch) => {
			dispatch({
				type: SHOW_ALERT,
				payload: {
					alertTitle: 'Error',
					alertMessage: Object.values(validationResponse)[0][0],
				},
			})
		}
	}

	return (dispatch, getState) => {
		dispatch({ type: AdminKeys.CIRCUIT_API_TEST_START })
		const apiCall = () => {
			//TODO: Convert to interval.
			setTimeout(() => {
				const { circuitApiTestActive } = getState().Admin
				if (circuitApiTestActive) {
					apiRequest('GET', `get_wait_times/${circuitUID}`, null, false)
						.then((response) => {
							console.log('Circuit Api Response', response)
							dispatch({
								type: AdminKeys.FETCH_CIRCUIT_API_RESPONSE,
								payload: response,
							})
						})
						.catch((error) => {
							dispatch({
								type: AdminKeys.FETCH_CIRCUIT_API_RESPONSE,
								payload: null,
							})
							console.log('Circuit Api Error', error)
						})
					apiCall()
				}
			}, circuitWaitTime * 1000)
		}
		apiRequest('GET', `get_wait_times/${circuitUID}`, null, false)
			.then((response) => {
				console.log('Circuit Api Response', response)
				dispatch({
					type: AdminKeys.FETCH_CIRCUIT_API_RESPONSE,
					payload: response,
				})
			})
			.catch((error) => {
				dispatch({
					type: AdminKeys.FETCH_CIRCUIT_API_RESPONSE,
					payload: null,
				})
				console.log('Circuit Api Error', error)
			})
		apiCall()
	}
}
export const stopCircuitApiTest = () => ({ type: AdminKeys.CIRCUIT_API_TEST_STOP })
