import { TrackingKeys } from './../../actions/types'

const INITIAL_STATE = {
	route: undefined,
	routeLoaded: false,
	routeWatcherRef: undefined,
}

export default (state = INITIAL_STATE, action) => {
	const { FETCH_ROUTE, SAVE_ROUTE_WATCHER, REMOVE_ROUTE_WATCHER } = TrackingKeys

	switch (action.type) {
		case FETCH_ROUTE:
			return { ...state, route: action.payload, routeLoaded: true }
		case SAVE_ROUTE_WATCHER:
			return { ...state, routeWatcherRef: action.payload }
		case REMOVE_ROUTE_WATCHER:
			return {
				...state,
				route: INITIAL_STATE.route,
				routeLoaded: INITIAL_STATE.routeLoaded,
				routeWatcherRef: INITIAL_STATE.routeWatcherRef,
			}

		default:
			return state
	}
}
