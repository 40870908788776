import { CoordinatorKeys } from './../../actions/types'

const INITIAL_STATE = {
	circuitsLive: undefined,
	circuitsLiveLoaded: false,
	circuitsLiveWatcherRef: undefined,

	circuitsHistory: undefined,
	circuitsHistoryLoaded: false,
	circuitsHistoryWatcherRef: undefined,

	circuit: undefined,
	circuitLoaded: false,
	circuitWatcherRef: undefined,

	circuitLogs: undefined,
	circuitLogsLoaded: false,
	circuitLogsWatcherRef: undefined,

	circuitWaitTimes: undefined,
	circuitWaitTimesWatchers: undefined,
	circuitWaitTimesIntervals: undefined,

	paxCounts: undefined,
	paxCountsLoaded: false,
	paxCountsWatcherRef: undefined,

	paxDrivers: undefined,
}

export default (state = INITIAL_STATE, action) => {
	const {
		FETCH_CIRCUITS_LIVE,
		SAVE_CIRCUITS_LIVE_WATCHER,
		REMOVE_CIRCUITS_LIVE_WATCHER,

		FETCH_CIRCUITS_HISTORY,
		SAVE_CIRCUITS_HISTORY_WATCHER,
		REMOVE_CIRCUITS_HISTORY_WATCHER,

		FETCH_WAIT_TIME,
		SAVE_WAIT_TIME_WATCHER,
		REMOVE_WAIT_TIME_WATCHER,

		FETCH_CIRCUIT,
		SAVE_CIRCUIT_WATCHER,
		REMOVE_CIRCUIT_WATCHER,

		FETCH_CIRCUIT_LOGS,
		SAVE_CIRCUIT_LOGS_WATCHER,
		REMOVE_CIRCUIT_LOGS_WATCHER,

		FETCH_PAX_COUNTS,
		SAVE_PAX_COUNTS_WATCHER,
		REMOVE_PAX_COUNTS_WATCHER,

		FETCH_PAX_DRIVERS,
		CLEAR_PAX_DRIVERS,
	} = CoordinatorKeys

	switch (action.type) {
		case FETCH_CIRCUITS_LIVE:
			return { ...state, circuitsLive: action.payload, circuitsLiveLoaded: true }
		case SAVE_CIRCUITS_LIVE_WATCHER:
			return { ...state, circuitsWatcherRef: action.payload }
		case REMOVE_CIRCUITS_LIVE_WATCHER:
			return {
				...state,
				circuitsLive: INITIAL_STATE.circuitsLive,
				circuitsLiveLoaded: INITIAL_STATE.circuitsLiveLoaded,
				circuitsLiveWatcherRef: INITIAL_STATE.circuitsLiveWatcherRef,
			}

		case FETCH_CIRCUITS_HISTORY:
			return { ...state, circuitsHistory: action.payload, circuitsHistoryLoaded: true }
		case SAVE_CIRCUITS_HISTORY_WATCHER:
			return { ...state, circuitsHistoryWatcherRef: action.payload }
		case REMOVE_CIRCUITS_HISTORY_WATCHER:
			return {
				...state,
				circuitsHistory: INITIAL_STATE.circuitsHistory,
				circuitsHistoryLoaded: INITIAL_STATE.circuitsHistoryLoaded,
				circuitsHistoryWatcherRef: INITIAL_STATE.circuitsHistoryWatcherRef,
			}

		case FETCH_WAIT_TIME:
			return { ...state, circuitWaitTimes: action.payload }
		case SAVE_WAIT_TIME_WATCHER: {
			const { waitTimeWatcherRef, waitTimeInterval } = action.payload

			return {
				...state,
				circuitWaitTimesWatchers: waitTimeWatcherRef,
				circuitWaitTimesIntervals: waitTimeInterval,
			}
		}
		case REMOVE_WAIT_TIME_WATCHER:
			return {
				...state,
				circuitWaitTimes: INITIAL_STATE.circuitWaitTimes,
				circuitWaitTimesWatchers: INITIAL_STATE.circuitWaitTimesWatchers,
				circuitWaitTimesIntervals: INITIAL_STATE.circuitWaitTimesIntervals,
			}

		case FETCH_CIRCUIT:
			return { ...state, circuit: action.payload, circuitLoaded: true }
		case SAVE_CIRCUIT_WATCHER:
			return { ...state, circuitWatcherRef: action.payload }
		case REMOVE_CIRCUIT_WATCHER:
			return {
				...state,
				circuit: INITIAL_STATE.circuit,
				circuitLoaded: INITIAL_STATE.circuitLoaded,
				circuitWatcherRef: INITIAL_STATE.circuitWatcherRef,
			}

		case FETCH_CIRCUIT_LOGS:
			return { ...state, circuitLogs: action.payload, circuitLogsLoaded: true }
		case SAVE_CIRCUIT_LOGS_WATCHER:
			return { ...state, circuitLogsWatcherRef: action.payload }
		case REMOVE_CIRCUIT_LOGS_WATCHER:
			return {
				...state,
				circuitLogs: INITIAL_STATE.circuitLogs,
				circuitLogsLoaded: INITIAL_STATE.circuitLogsLoaded,
				circuitLogsWatcherRef: INITIAL_STATE.circuitLogsWatcherRef,
			}

		case FETCH_PAX_COUNTS:
			return { ...state, paxCounts: action.payload, paxCountsLoaded: true }
		case SAVE_PAX_COUNTS_WATCHER:
			return { ...state, paxCountsWatcherRef: action.payload }
		case REMOVE_PAX_COUNTS_WATCHER:
			return {
				...state,
				paxCounts: INITIAL_STATE.paxCounts,
				paxCountsLoaded: INITIAL_STATE.paxCountsLoaded,
				paxCountsWatcherRef: INITIAL_STATE.paxCountsWatcherRef,
			}

		case FETCH_PAX_DRIVERS:
			return { ...state, paxDrivers: action.payload }
		case CLEAR_PAX_DRIVERS:
			return { ...state, paxDrivers: INITIAL_STATE.paxDrivers }

		default:
			return state
	}
}
