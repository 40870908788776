export const colors = {
    white: '#ffffff',
    lightestGray: '#f6f6f6', //Background
    lighterGray: '#eff3f4', //Borders (Input / Nav)
    lightGray: '#e5e5e5',
    gray: '#a0a0a0',
    darkGray: '#5e5e5e',
    darkerGray: '#555555',
    black: '#333333',

    primary: '#5ac9ec',

    red: '#ff0000',
    blue: '#5ac9ec',
    green: '#4ADEC2',

    shadow: 'rgba(0,0,0,0.1)',
    shadowBottom: 'rgba(0, 0, 0, 0.1) 0px 15px 15px -15px, rgba(0, 0, 0, 0.05) 0px 5px 5px -5px',
    shadowTop: 'rgba(0, 0, 0, 0.1) 0px -15px 15px -15px, rgba(0, 0, 0, 0.05) 0px -5px 5px -5px',
    shadowRight: 'rgba(0, 0, 0, 0.1) 15px 0px 15px -15px, rgba(0, 0, 0, 0.05) 5px 0px 5px -5px',
    shadowLeft: 'rgba(0, 0, 0, 0.1) -15px 0px 15px -15px, rgba(0, 0, 0, 0.05) 5px 0px 5px -5px',
    shadowCenter: 'rgba(0, 0, 0, 0.1) 0px 0px 6px 0px, rgba(0, 0, 0, 0.05) 0px 0px 2px 0px',

    gradient: (degree) => `linear-gradient(${degree}deg, #74C8AC, #5ACAEE)`,
}

export const layout = {
    gutterSize: 30,
}
