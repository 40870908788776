import { CoordinatorKeys } from './../../actions/types'

const INITIAL_STATE = {
	fleet: undefined,
	fleetLoaded: false,
	fleetWatcherRef: undefined,
	fleetRefresher: undefined,
	drivers: undefined,
	driverLocations: undefined,
}

export default (state = INITIAL_STATE, action) => {
	const {
		FETCH_FLEET,
		SAVE_FLEET_WATCHER,
		REMOVE_FLEET_WATCHER,
		SAVE_FLEET_REFRESHER,
		FETCH_FLEET_DRIVERS,
		FETCH_FLEET_DRIVER_LOCATIONS,
	} = CoordinatorKeys

	switch (action.type) {
		case FETCH_FLEET:
			return { ...state, fleet: action.payload, fleetLoaded: true }
		case SAVE_FLEET_WATCHER:
			return { ...state, fleetWatcherRef: action.payload }
		case REMOVE_FLEET_WATCHER:
			return {
				...state,
				fleet: INITIAL_STATE.fleet,
				fleetLoaded: INITIAL_STATE.fleetLoaded,
				fleetWatcherRef: INITIAL_STATE.fleetWatcherRef,
			}

		case SAVE_FLEET_REFRESHER:
			return { ...state, fleetRefresher: action.payload }
		case FETCH_FLEET_DRIVERS:
			return { ...state, drivers: action.payload }
		case FETCH_FLEET_DRIVER_LOCATIONS:
			return { ...state, driverLocations: action.payload }

		default:
			return state
	}
}
