import firebase from 'firebase/app'
import 'firebase/database'

const s4 = () => {
	return Math.floor((1 + Math.random()) * 0x10000)
		.toString(16)
		.substring(1)
}

export const generateRandomCode = (length = 2) => {
	let code = ''
	for (let i = 0; i < length; i++) {
		code += s4()
	}
	return code
}

export const generateAccessCode = (length) =>
	new Promise((res, rej) => {
		let numberOfAttempts = 0
		const generateAndCheck = () => {
			if (numberOfAttempts > 4) {
				console.error('Generating Code Failed to many times')
				return rej()
			}

			const code = generateRandomCode(length)
			firebase
				.database()
				.ref(`AccessCodes/${code}`)
				.once('value')
				.then((snapshot) => {
					if (snapshot.val()) {
						numberOfAttempts++
						generateAndCheck()
					}
					return res(code)
				})
				.catch((error) => {
					console.error(error)
					numberOfAttempts++
					generateAndCheck()
				})
		}
		generateAndCheck()
	})
export const generateDriverCode = (length) =>
	new Promise((res, rej) => {
		let numberOfAttempts = 0
		const generateAndCheck = () => {
			if (numberOfAttempts > 4) {
				console.error('Generating Code Failed to many times')
				return rej()
			}

			const code = generateRandomCode(length)
			firebase
				.database()
				.ref(`DriverCodes/${code}`)
				.once('value')
				.then((snapshot) => {
					if (snapshot.val()) {
						numberOfAttempts++
						generateAndCheck()
					}
					return res(code)
				})
				.catch((error) => {
					console.error(error)
					numberOfAttempts++
					generateAndCheck()
				})
		}
		generateAndCheck()
	})
