import { colors } from './../../../config/styles'

export default {
    content: {
        width: '90%',
        maxWidth: 600,
        margin: '100px auto',
    },
    title: {
        position: 'relative',
        width: '100%',
        textAlign: 'center',
        fontSize: 24,
        marginBottom: 20,
        color: colors.darkGray,
        paddingLeft: 30,
        paddingTop: 30,
        paddingRight: 30,
        paddingBottom: 0,
    },
    message: {
        position: 'relative',
        width: '100%',
        textAlign: 'center',
        fontSize: 18,
        lineHeight: 1.25,
        marginBottom: 30,
        padding: '0px 40px',
        color: colors.darkGray,
    },
    button: {
        position: 'relative',
        width: '100%',
        textAlign: 'center',
        fontSize: 18,
        fontWeight: 100,
        letterSpacing: 0.5,
        color: colors.primary,
        backgroundColor: colors.white,
        borderTop: `1px solid ${colors.primary}`,
        borderRight: 'none',
        borderBottom: 'none',
        borderLeft: 'none',
        lineHeight: 2,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        backgroundImage: 'none',
        transition: 'border 0.2s ease-in, color 0.2s ease-in, background-color 0.2s ease-in',

        ':active': {
            color: colors.white,
            backgroundColor: colors.primary,
            borderTop: `1px solid ${colors.primary}`,
            borderRight: 'none',
            borderBottom: 'none',
            borderLeft: 'none',
        },
        ':hover': {
            color: colors.white,
            backgroundColor: colors.primary,
            borderTop: `1px solid ${colors.primary}`,
            borderRight: 'none',
            borderBottom: 'none',
            borderLeft: 'none',
        },
    },
}
