export const customTableFilter = (filter, row) => {
	const id = filter.pivotId || filter.id
	if (row[id] !== null && (typeof row[id] === 'string' || typeof row[id] === 'number')) {
		return row[id] !== undefined
			? String(row[id])
					.toLowerCase()
					.includes(filter.value.toLowerCase())
			: true
	}
}

export const filterDateColumn = (dateFormatFunction, filter, row) => {
	//TODO: Update and write tests
	const id = filter.pivotId || filter.id
	const rowValue = row[id]

	if (rowValue) {
		const dateString = dateFormatFunction(rowValue)
		return dateString.toLowerCase().includes(filter.value.toLowerCase())
	}
}
