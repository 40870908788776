import { combineReducers } from 'redux'

//Coordinator
import Coordinator from './CoordinatorReducer'
import Route from './RouteReducer'
import Circuit from './CircuitReducer'
import Program from './ProgramReducer'
import Fleet from './FleetReducer'

export default combineReducers({
	Coordinator,
	Route,
	Circuit,
	Program,
	Fleet,
})
