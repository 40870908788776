import { AdminKeys } from './../actions/types'

const INITIAL_STATE = {
	circuitApiTestActive: false,
	circuitApiResponse: undefined,

	organizations: undefined,
	organizationsWatcherRef: undefined,
	organizationsLoaded: false,

	organizationModel: undefined,
	organizationWatcherRef: undefined,
	organizationModelLoaded: false,

	coordinators: undefined,
	coordinatorsWatcherRef: undefined,
	coordinatorsLoaded: false,

	coordinatorModel: undefined,
	coordinatorWatcherRef: undefined,
	coordinatorModelLoaded: false,
}

export default (state = INITIAL_STATE, action) => {
	const {
		FETCH_ORGANIZATIONS,
		SAVE_ORGANIZATIONS_WATCHER,
		REMOVE_ORGANIZATIONS_WATCHER,

		FETCH_ORGANIZATION,
		SAVE_ORGANIZATION_WATCHER,
		REMOVE_ORGANIZATION_WATCHER,

		FETCH_COORDINATORS,
		SAVE_COORDINATORS_WATCHER,
		REMOVE_COORDINATORS_WATCHER,

		FETCH_COORDINATOR,
		SAVE_COORDINATOR_WATCHER,
		REMOVE_COORDINATOR_WATCHER,

		CIRCUIT_API_TEST_START,
		CIRCUIT_API_TEST_STOP,
		FETCH_CIRCUIT_API_RESPONSE,

		RESET,
	} = AdminKeys

	switch (action.type) {
		case FETCH_ORGANIZATIONS:
			return { ...state, organizations: action.payload, organizationsLoaded: true }
		case SAVE_ORGANIZATIONS_WATCHER:
			return { ...state, organizationsWatcherRef: action.payload }
		case REMOVE_ORGANIZATIONS_WATCHER:
			return {
				...state,
				organizationsWatcherRef: INITIAL_STATE.organizationsWatcherRef,
				organizationsLoaded: INITIAL_STATE.organizationsLoaded,
			}

		case FETCH_ORGANIZATION:
			return { ...state, organizationModel: action.payload, organizationModelLoaded: true }
		case SAVE_ORGANIZATION_WATCHER:
			return { ...state, organizationWatcherRef: action.payload }
		case REMOVE_ORGANIZATION_WATCHER:
			return {
				...state,
				organizationWatcherRef: INITIAL_STATE.organizationWatcherRef,
				organizationModelLoaded: INITIAL_STATE.organizationModelLoaded,
			}

		case FETCH_COORDINATORS:
			return { ...state, coordinators: action.payload, coordinatorsLoaded: true }
		case SAVE_COORDINATORS_WATCHER:
			return { ...state, coordinatorsWatcherRef: action.payload }
		case REMOVE_COORDINATORS_WATCHER:
			return {
				...state,
				coordinatorsWatcherRef: INITIAL_STATE.coordinatorsWatcherRef,
				coordinatorsLoaded: INITIAL_STATE.coordinatorsLoaded,
			}

		case FETCH_COORDINATOR:
			return { ...state, coordinatorModel: action.payload, coordinatorModelLoaded: true }
		case SAVE_COORDINATOR_WATCHER:
			return { ...state, coordinatorWatcherRef: action.payload }
		case REMOVE_COORDINATOR_WATCHER:
			return {
				...state,
				coordinatorWatcherRef: INITIAL_STATE.coordinatorWatcherRef,
				coordinatorModelLoaded: INITIAL_STATE.coordinatorModelLoaded,
			}

		case CIRCUIT_API_TEST_START:
			return { ...state, circuitApiTestActive: true }
		case CIRCUIT_API_TEST_STOP:
			return { ...state, circuitApiTestActive: false }
		case FETCH_CIRCUIT_API_RESPONSE:
			return { ...state, circuitApiResponse: action.payload }

		case RESET:
			return INITIAL_STATE

		default:
			return state
	}
}
